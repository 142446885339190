export const AVAILABILITY = {
  QUANTITY: "QUANTITY",
  TIME_LIMITATION: "TIME_LIMITATION"
};

export const RESTRICTIONS = {
  VALID_UNTIL_DATE: "Valid until date",
  CUSTOM: "Custom"
};

export const PRODUCT_TYPE = {
  PRODUCT: "Product",
  SERVICE: "Service"
};
