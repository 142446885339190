export const COLOR_DEFAULTS = [
  {
    name: 'Black',
    id: 1,
    color: '#000000',
  },
  {
    name: 'White',
    id: 2,
    color: '#fffff',
  },
  {
    name: 'Multi color',
    id: 3,
    color: '#F6AF18',
  },
  {
    name: 'Cold Red',
    id: 4,
    color: '#E1596B',
  },
  {
    name: 'Bright Red',
    id: 5,
    color: '#e15a4b',
  },
  {
    name: 'Rose Red',
    id: 6,
    color: '#BC4E63',
  },
  {
    name: 'Royal Red',
    id: 7,
    color: '#ba2442',
  },
  {
    name: 'Carmine Red',
    id: 8,
    color: '#A5262A',
  },
  {
    name: 'Bordeaux Red',
    id: 9,
    color: '#5e1821',
  },
  {
    name: 'Orange',
    id: 10,
    color: '#E3672A',
  },
  {
    name: 'Dark Orange',
    id: 11,
    color: '#b84a2c',
  },
  {
    name: 'Carrot',
    id: 12,
    color: '#EEA62D',
  },
  {
    name: 'Honey',
    id: 13,
    color: '#f7c56f',
  },
  {
    name: 'Bronze',
    id: 14,
    color: '#f6af18',
  },
  {
    name: 'Brown',
    id: 15,
    color: '#7f3524',
  },
  {
    name: 'Light Brown',
    id: 16,
    color: '#AA6133',
  },
  {
    name: 'Dark Brown',
    id: 17,
    color: '#5a3722',
  },
  {
    name: 'Gold',
    id: 18,
    color: '#f6ce18',
  },
  {
    name: 'Dark Gold',
    id: 19,
    color: '#ac8032',
  },

  {
    name: 'Beige',
    id: 20,
    color: '#F8E9AE',
  },
  {
    name: 'Light Beige',
    id: 21,
    color: '#fffbdc',
  },
  {
    name: 'Dark Beige',
    id: 22,
    color: '#CBB67F',
  },
  {
    name: 'Khaki',
    id: 23,
    color: '#c6c094',
  },
  {
    name: 'Yellow',
    id: 24,
    color: '#faeb25',
  },
  {
    name: 'Light Yellow',
    id: 25,
    color: '#eee9c7',
  },
  {
    name: 'Warm Yellow',
    id: 26,
    color: '#f9ce22',
  },
  {
    name: 'Cream Yellow',
    id: 27,
    color: '#fad5a5',
  },
  {
    name: 'Sand Yellow',
    id: 28,
    color: '#d7c669',
  },
  {
    name: 'Lemon Yellow',
    id: 29,
    color: '#f8f18a',
  },
  {
    name: 'Green',
    id: 30,
    color: '#3eab47',
  },
  {
    name: 'Lime Green',
    id: 31,
    color: '#c5d634',
  },
  {
    name: 'Bright Green',
    id: 32,
    color: '#3bb189',
  },
  {
    name: 'Pale Green',
    id: 33,
    color: '#add6b4',
  },
  {
    name: 'Olive Green',
    id: 34,
    color: '#566931',
  },
  {
    name: 'Dark Green',
    id: 35,
    color: '#154634',
  },
  {
    name: 'Cold Green',
    id: 36,
    color: '#42837b',
  },
  {
    name: 'Blue',
    id: 37,
    color: '#5290c0',
  },
  {
    name: 'Light Blue',
    id: 38,
    color: '#779dc7',
  },
  {
    name: 'Medium Blue',
    id: 39,
    color: '#4f639f',
  },
  {
    name: 'Ice Blue',
    id: 40,
    color: '#dff2f5',
  },
  {
    name: 'Baby Blue',
    id: 41,
    color: '#b9e1eb',
  },
  {
    name: 'Water Blue',
    id: 42,
    color: '#87cdd6',
  },
  {
    name: 'Neon Blue',
    id: 43,
    color: '#6ec5ce',
  },
  {
    name: 'Turquoise Blue',
    id: 44,
    color: '#75c3b7',
  },
  {
    name: 'Marine Blue',
    id: 45,
    color: '#b5dbc5',
  },
  {
    name: 'Sky Blue',
    id: 46,
    color: '#8dbbda',
  },
  {
    name: 'Ocean Blue',
    id: 47,
    color: '#5095ab',
  },
  {
    name: 'Navy Blue',
    id: 48,
    color: '#26337a',
  },
  {
    name: 'Dark Blue',
    id: 49,
    color: '#242651',
  },
  {
    name: 'Night Blue',
    id: 50,
    color: '#26396a',
  },
  {
    name: 'Indigo Blue',
    id: 51,
    color: '#492963',
  },
  {
    name: 'Violet',
    id: 52,
    color: '#7d5291',
  },
  {
    name: 'Purple',
    id: 53,
    color: '#864d8e',
  },
  {
    name: 'Light Purple',
    id: 54,
    color: '#d6b9cf',
  },
  {
    name: 'Lilac',
    id: 55,
    color: '#bdafca',
  },
  {
    name: 'Lavander',
    id: 56,
    color: '#8d87b4',
  },
  {
    name: 'Light Lavander',
    id: 57,
    color: '#afb5d1',
  },
  {
    name: 'Pink',
    id: 58,
    color: '#e892b4',
  },
  {
    name: 'Barbie Pink',
    id: 59,
    color: '#df5492',
  },
  {
    name: 'Dark Pink',
    id: 60,
    color: '#ce1870',
  },
  {
    name: 'Magenta Pink',
    id: 61,
    color: '#cd3c86',
  },
  {
    name: 'Peach Pink',
    id: 62,
    color: '#e98c8c',
  },
  {
    name: 'Pale Pink',
    id: 63,
    color: '#f4d6d6',
  },
  {
    name: 'Rose Gold',
    id: 64,
    color: '#fbe8e8',
  },
  {
    name: 'Pearl',
    id: 65,
    color: '#f5f5f3',
  },
  {
    name: 'Silver',
    id: 66,
    color: '#d3d7d5',
  },
  {
    name: 'Gray',
    id: 67,
    color: '#929b9e',
  },
  {
    name: 'Light Gray',
    id: 68,
    color: '#bbbbb6',
  },
  {
    name: 'Trout Gray',
    id: 69,
    color: '#c2c0b0',
  },
  {
    name: 'Ash Gray',
    id: 70,
    color: '#707471',
  },
  {
    name: 'Dark Gray',
    id: 71,
    color: '#3b3e40',
  },
];
