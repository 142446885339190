import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

const ProductApiService = {
  URL: "",
  async init() {
    Vue.use(VueAxios, axios);
    const resp = await Vue.axios.get(`api/url/get-base-uri`);
    this.URL = resp.data;
  },

  async getProduct(resource, slug) {
    return Vue.axios.get(this.URL + `${resource}/${slug}`).catch(error => {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] ProductApiService ${error}`);
    });
  }
};

export default ProductApiService;
