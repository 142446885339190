import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

const MobileApiService = {
  URL: "",
  async init() {
    Vue.use(VueAxios, axios);
    const resp = await Vue.axios.get(`api/url/get-base-uri`);
    this.URL = resp.data;
  },

  MOBILE_API: "mobileapi",

  post(url, data) {
    try {
      return Vue.axios.post(`${this.URL}${this.MOBILE_API}/${url}`, data);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] MobileApiService ${error}`);
    }
  }
};

export default MobileApiService;
